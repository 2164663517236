import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import decode from 'jwt-decode';
import { environment } from '../../environments/environment';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    baseUrl: string = environment.serverUrl;

    @Output() loginEndModalOpen: EventEmitter<boolean> = new EventEmitter();

    constructor(private http: HttpClient) {}

    login(newLogin: any, subdomain: any) {
        console.log('en service de new login');
        console.log(newLogin);
        return this.http.post<any>(`${this.baseUrl}/api/login/`, {
            email: newLogin.email,
            password: newLogin.password,
            subdomain,
        });
    }

    logout() {
        console.log('logout user de auth service');
        return this.http.post(`${this.baseUrl}/api/logout`, {}).subscribe(
            (data) => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('currentUser');
            },
            (err) => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('currentUser');
            },
        );
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('access_token');

        if (!token) {
            return false;
        }

        let tokenPayload: any = decode(token, {});
        let dateNow = new Date();
        let isAuthenticated = true;
        if (tokenPayload.exp < Date.now() / 1000) {
            isAuthenticated = false;
        }

        return isAuthenticated;
    }

    public getTokenExpirationTime() {
        const token = localStorage.getItem('access_token');
        let tokenPayload: any = decode(token, {});
        return tokenPayload.exp;
    }

    openLoginModal(open: boolean) {
        this.loginEndModalOpen.emit(open);
    }

    refreshToken(): Observable<boolean> {
        console.log('refresh token');
        return this.http
            .post<any>(`${this.baseUrl}/api/login/refresh`, {})
            .pipe(
                map((result) => {
                    localStorage.removeItem('access_token');
                    localStorage.setItem('access_token', result.token);
                    return true;
                }),
            );
    }

    staffLoginAuthentication(data) {
        return this.http.post<any>(`${this.baseUrl}/api/login/2FA`, data);
    }

    customerAuthentication(data) {
        return this.http.post<any>(
            `${this.baseUrl}/api/login/customer/2FA`,
            data,
        );
    }

    emailResetPasswordLink(email, subdomain): Observable<HttpResponse<any>> {
        return this.http.post<any>(
            `${this.baseUrl}/api/password/reset`,
            { email: email, subdomain: subdomain },
            { observe: 'response' },
        );
    }

    smsResetPasswordLink(mobilePhone, email): Observable<HttpResponse<any>> {
        return this.http.post<any>(
            `${this.baseUrl}/api/reset_password/sms`,
            { phone: mobilePhone, email: email },
            { observe: 'response' },
        );
    }
}
