import { TextFieldModule } from '@angular/cdk/text-field';
import {
    CommonModule,
    CurrencyPipe,
    DatePipe,
    NgClass,
    NgFor,
    NgIf,
} from '@angular/common';
import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipEditedEvent } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { inject } from '@angular/core';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { QRCodeModule } from 'angularx-qrcode';
import { saveAs } from 'file-saver';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClipboardService } from 'ngx-clipboard';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
export interface Email {
    email: string;
}
interface NavigatorWithMsSaveOrOpenBlob extends Navigator {
    msSaveOrOpenBlob?: (blob: Blob, defaultName?: string) => boolean;
}
@Component({
    styleUrls: ['./information.scss'],
    selector: 'information-modal',
    templateUrl: './information.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        NgClass,
        NgIf,
        MatDatepickerModule,
        NgFor,
        MatCheckboxModule,
        DatePipe,
        CurrencyPipe,
        CommonModule,
        MatChipsModule,
        QRCodeModule,
        MatMenuModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatSelectModule,
    ],
})
export class InformationModalComponent implements OnInit {
    @ViewChild('qrCode', { static: false }) qrCode: ElementRef;

    @ViewChild('receiptContent') receiptContent: ElementRef;
    @ViewChild('downloadLink') downloadLink: ElementRef;
    inputValue: string;

    emailFormControl = new FormControl('', [
        Validators.required,
        Validators.email,
    ]);
    form: FormGroup;
    selectedFee: string;
    feesUserModal: boolean;
    feesUser: string = 'false';
    fees = [
        {
            id: 1,
            label: 'End Client pays CC Processing Fees & Service Charge.',
        },
        {
            id: 2,
            label: 'End Client pays CC Processing Fees & Does Not Pay Service Charge, Submerchant pays Service Charge',
        },
        {
            id: 3,
            label: 'End Client pays CC NET PAYMENT Submerchant pays for the Processing Fees and Service Charges. ',
        },
        {
            id: 4,
            label: 'End Client pays Service Charge. Does not pay Processing. Submerchant pays for Processing.',
        },
    ];
    id_type_animal: string[] = [];
    id_subtype_animal: string[] = [];
    private idCheckout: any;
    animalTypes: any[] = [];
    animalSubtypes: any[] = [];
    subPetsTypes: any[];
    amount: any;
    notificationGroup: any;
    /**
     * Constructor
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,

        private fb: FormBuilder,
        private clipboardService: ClipboardService,
        private _matDialog: MatDialog,
    ) {
        this.form = this.fb.group({
            emails: [[], Validators.required],
            emailInput: ['', [Validators.required, Validators.email]],
        });
    }

    getSelectedFee() {
        if (this.selectedFee) {
            console.log('Selected Fee: ' + this.selectedFee);
        } else {
            console.log('Please select a fee option.');
        }
    }
    get emailInput() {
        return this.form.get('emailInput');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.feesUser = this.data?.feesUserModal;
        this.selectedFee = this.data?.selectedFee;
        this.idCheckout = this.data?.idCheckout;
        this.notificationGroup = this.data?.notificationGroup;
    }
    updateFees(e) {
        this.feesUser = e.target.value;
        console.log(this.feesUser);
    }
    onAnimalTypeChange(event: MatSelectChange) {
        // console.log('--->', event.value);
        const selectedAnimals = this.subPetsTypes.filter((animal) =>
            event.value.some((e) => e === animal.type),
        );
        // console.log('-->', selectedAnimals);

        if (selectedAnimals) {
            this.animalSubtypes = selectedAnimals;
        }
    }

    get selectedAnimalTypes() {
        return this.animalTypes.filter((type) =>
            this.id_type_animal.includes(type._id),
        );
    }

    get selectedAnimalSubtypes() {
        return this.animalSubtypes.filter((type) =>
            this.id_subtype_animal.includes(type._id),
        );
    }

    removeSelectedType(id: string) {
        this.id_type_animal = this.id_type_animal.filter(
            (typeId) => typeId !== id,
        );
    }

    removeSelectedSubtype(id: string) {
        this.id_subtype_animal = this.id_subtype_animal.filter(
            (subtypeId) => subtypeId !== id,
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    closeModal(userConfirmedTx: boolean) {
        if (this.data.onConfirm) {
            this.data.onConfirm();
        }
    }

    addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    emails: Email[] = [];

    announcer = inject(LiveAnnouncer);

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Asegúrate de que el valor ingresado sea un correo electrónico válido
        if ((value || '').trim()) {
            // Utiliza el control del formulario para validar el valor ingresado
            const emailInputControl = this.form.get('emailInput');

            if (emailInputControl.valid) {
                // Agrega el correo electrónico a tu lista
                this.emails.push({ email: value.trim() });
            } else {
                // Muestra un mensaje de error o realiza alguna acción de manejo de errores
                console.error('Correo electrónico no válido');
            }
        }

        if (input) {
            input.value = '';
        }
    }

    remove(email: Email): void {
        const index = this.emails.indexOf(email);

        if (index >= 0) {
            this.emails.splice(index, 1);

            this.announcer.announce(`Removed ${email}`);
        }
    }

    edit(email: Email, event: MatChipEditedEvent) {
        const value = event.value.trim();

        if (!value) {
            this.remove(email);
            return;
        }

        const index = this.emails.indexOf(email);
        if (index >= 0) {
            this.emails[index].email = value;
        }
    }

    downloadQR(qrCodeElement) {
        // Obtener la referencia al elemento del código QR
        const qrCodeCanvas =
            qrCodeElement.qrcElement.nativeElement.querySelector('canvas');

        if (qrCodeCanvas) {
            const url = qrCodeCanvas.toDataURL('image/png');

            const blobData = this.convertBase64ToBlob(url);

            if (
                window.navigator &&
                (window.navigator as NavigatorWithMsSaveOrOpenBlob)
                    .msSaveOrOpenBlob
            ) {
                (
                    window.navigator as NavigatorWithMsSaveOrOpenBlob
                ).msSaveOrOpenBlob(
                    blobData,
                    `${this.data?.subdomain} ${this.data?.user_first_name}-${this.data?.user_last_name}.png`,
                );
            } else {
                saveAs(
                    blobData,
                    `${this.data?.subdomain} ${this.data?.user_first_name}-${this.data?.user_last_name}.png`,
                );
            }
        }
    }

    convertBase64ToBlob(base64Data: string): Blob {
        const byteCharacters = atob(base64Data.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: 'image/png' });
    }
    copyLinkToClipboard() {
        this.clipboardService.copyFromContent(this.data.linkCustomer);
        // this.snackBar.open('Enlace copiado al portapapeles', 'Cerrar', {
        //     duration: 3000,
        // });
    }
    sendEmailReceipt() {
        this.sendEmailModal();
    }

    sendEmailModal() {
        this._matDialog
            .open(InformationModalComponent, {
                autoFocus: false,
                data: {
                    title: 'Send Invoice',
                    icon: 'heroicons_solid:inbox-arrow-down',
                    inputEmail: true,
                    cancelText: 'Cancel',
                    confirmText: 'Send Email',
                    onConfirm: () => {},
                },
            })
            .afterClosed()
            .subscribe((res) => {
                // console.log('data', this.data);
                if (res.userConfirmedTx) {
                    const data = {
                        Amount: this.data.total,
                        FirstName: this.data.first_name,
                        LastName: this.data.last_name,
                        fees: this.data.fees,
                        EmailAddress: res.emails,
                        subdomain: this.data.nameSubermechant,
                        confirmation: this.data.confirmation_id,
                        medium: 'collect',
                    };
                    console.log('data emial', data);
                }
            });
    }
    printReceipt() {
        let printContents = this.receiptContent.nativeElement.innerHTML;
        let popupWin = window.open(
            '',
            '_blank',
            'top=0,left=0,height=100%,width=auto',
        );
        popupWin.document.open();
        popupWin.document.write(`
            <html>
                <head>
                <script src="https://cdn.tailwindcss.com"></script>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                    <style>
                    body{
                        display:flex;
                        flex-direction: column;
                        align-items:center;
                        justify-content:center;
                    }
                    .itempayment{
                        display:flex;
                        flex-direction:column;
                        align-items:center;
                        justify-content:center;
                    }
                    </style>
                </head>
                <body onload="window.print();window.close()">${printContents}</body>
            </html>`);
        popupWin.document.close();
    }
}
