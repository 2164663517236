import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class LandingService {
    baseUrl: string = environment.serverUrl;
    constructor(private http: HttpClient) {}
    saveBrowser(id, data) {
        return this.http.post<any>(
            `${this.baseUrl}/api/browser-metrics/${id}/save_browser/`,
            data,
        );
    }
    getInfomationSubdomain(subdomain) {
        return this.http.get<any>(
            `${this.baseUrl}/api/homepage/get-information-homepage-english?subdomain=${subdomain}`,
        );
    }
    getSubdomain(subdomain) {
        return this.http.get<any>(
            `${this.baseUrl}/api/homepage/get-information-subdomain?subdomain=${subdomain}`,
        );
    }
}
