import { inject } from '@angular/core';
import { Router, CanActivateFn, CanActivateChildFn } from '@angular/router';
import { switchMap, of } from 'rxjs';
import { StorageService } from 'services/storage/storage.service';

export const AuthorizedGuard: CanActivateFn | CanActivateChildFn = (
    route,
    state,
) => {
    const router: Router = inject(Router);

    return inject(StorageService)
        .check()
        .pipe(
            switchMap((authenticated) => {
                if (!authenticated) {
                    const urlTree = router.parseUrl(`login`);
                    return of(urlTree);
                }

                // Allow the access
                return of(true);
            }),
        );
};
