<div class="flex flex-col flex-auto md:w-160 md:min-w-160 max-h-screen -m-6">
    <div *ngIf="data.closeModalLinkCuatomer" class="flex justify-end p-2">
        <button (click)="closeModal(false)">
            <mat-icon class="text-red-500">cancel</mat-icon>
        </button>
    </div>


    <div *ngIf="data.icon" class="m-auto mt-20">
        <mat-icon class="fuse-vertical-navigation-item-arrow icon-size-24" [svgIcon]="data.icon"></mat-icon>
    </div>
    <div *ngIf="data.title" class="mt-8 m-auto pl-5 pr-5">
        <h3 *ngIf="data.title" class="m-auto text-center">{{ data.title }}</h3>
        <p *ngIf="data.description" class="m-auto">
            {{ data.description }}
        </p>
        <input *ngIf="data.input" type="text" [placeholder]="data.inputPlaceholder"
            class="input form-control rounded p-3 mt-3" [(ngModel)]="inputValue" name="data" />
    </div>
    <div *ngIf="data.inputEmail" class="m-3">
        <form [formGroup]="form">
            <mat-form-field class="w-full mt-5">
                <mat-chip-grid #chipGrid aria-label="Enter Emails">
                    <mat-chip-row *ngFor="let email of emails" (removed)="remove(email)" [editable]="true"
                        (edited)="edit(email, $event)" [aria-description]="'press enter to edit ' + email.email">
                        {{ email.email }}
                        <button matChipRemove [attr.aria-label]="'remove ' + email.email">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    <input placeholder="Emails ...." [matChipInputFor]="chipGrid"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" formControlName="emailInput"
                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
                    <mat-error *ngIf="emailInput.hasError('required')">This field is required</mat-error>
                    <mat-error *ngIf="emailInput.hasError('email')">Please enter a valid email</mat-error>
                </mat-chip-grid>
            </mat-form-field>
        </form>
    </div>
    <div *ngIf="data.inputGroup" class="m-3">
        <div class="flex w-full gap-3">
            <div class="sm:col-span-2 w-full">
                <mat-form-field  [subscriptSizing]="'dynamic'" class="w-full">
                    <mat-select [(ngModel)]="id_type_animal" placeholder="Select Animal Type"
                        (selectionChange)="onAnimalTypeChange($event)" multiple>
                        <mat-option *ngFor="let type of animalTypes" [value]="type._id">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        
            <div class="sm:col-span-2 w-full">
                <mat-form-field [subscriptSizing]="'dynamic'" class="w-full">
                    <mat-select [(ngModel)]="id_subtype_animal" multiple placeholder="Select Animal Sub type" [disabled]="!animalSubtypes || animalSubtypes.length === 0">
                        <mat-option *ngFor="let type of animalSubtypes" [value]="type._id">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        
            <!-- Nuevo campo para solicitar valor de dinero -->
            <div class="sm:col-span-2 w-full">
                <mat-form-field [subscriptSizing]="'dynamic'" class="w-full">
                    <input matInput [(ngModel)]="amount" placeholder="Enter amount" type="number" min="0" step="0.01">
                    <mat-icon matSuffix>attach_money</mat-icon>
                </mat-form-field>
            </div>
        </div>
        
       <!-- Contenedor para etiquetas seleccionadas -->
    <div class="flex flex-wrap gap-2 mt-3">
        <span *ngFor="let selectedType of selectedAnimalTypes" class="tag tag-type">
            {{ selectedType.name }}
            <mat-icon class="remove-icon" (click)="removeSelectedType(selectedType._id)">close</mat-icon>
        </span>
        <span *ngFor="let selectedSubtype of selectedAnimalSubtypes" class="tag tag-subtype">
            {{ selectedSubtype.name }}
            <mat-icon class="remove-icon" (click)="removeSelectedSubtype(selectedSubtype._id)">close</mat-icon>
        </span>
    </div>
    </div>
    
    <div *ngIf="data.receipts">
        <div class="mt-3 mx-5 sm:mx-auto w-auto sm:max-w-[650px]">
            <!-- buttons menu -->
            <div class="bg-white shadow-sm p-3 rounded-xl sm:flex gap-4">

                <!-- send receipt email -->
                <div class="text-left flex justify-center flex-grow mt-3 sm:mt-0">
                    <button (click)="sendEmailModal()" mat-flat-button [color]="'primary'" class="min-h-[48px]">
                        <mat-icon>email</mat-icon>
                        <span class="ml-2 mr-1">Send Receipt Email</span>
                    </button>
                </div>

                <!-- print receipt -->
                <div class="text-left flex justify-center flex-grow mt-3 sm:mt-0" #printButton>
                    <button (click)="printReceipt()" mat-flat-button [color]="'primary'" class="min-h-[48px]">
                        <mat-icon [svgIcon]="'heroicons_solid:clipboard-document-list'"></mat-icon>
                        <span class="ml-2 mr-1">Print Receipt</span>
                    </button>
                </div>
            </div>

            <!-- receipt -->
            <div #receiptContent class="bg-white rounded-xl  mt-3 py-3 shadow-sm itempayment">
                <div class="flex justify-center items-center mb-2">
                    <img style="width: 100px;height: 100px;object-fit: cover; border-radius: 100%;"
                        src="{{data.imageLogoSubmerchan}}" alt="">
                </div>
                <div class="flex flex-col justify-center items-center">
                    <h3 class="p-3 text-black text-12 fw-bold m-0 rounded-top">Payment Receipt</h3>


                    <h2 class="text-12 text-black fw-bold">Total Amount</h2>
                    <span class="text-14 text-green-700 fw-bold text-3xl"> ${{ data.total }} </span>
                    <h4 class="text-14 text-black fw-bold">{{data.nameSubermechant}}</h4>
                </div>

                <div class="text-center p-0 max-w-[360px] sm:w-auto border-t border-b border-dark mx-auto">
                    <h3 i18n class="p-3 text-black text-12 fw-bold m-0 rounded-top">Payment Receipt</h3>
                </div>
                <div class="p-0 max-w-120 sm:w-auto border-b border-dark mx-auto">
                    <table class="data-box p-3 itempayment">
                        <br>
                        <tr>
                            <td style="width: 50%;" class="text-14 text-black fw-bold text-end">Payment To: </td>
                            <td style="width: 50%;" class="text-14 text-black fw-normal">&nbsp; {{ data.nameSubermechant
                                }}
                            </td>
                        </tr>
                        <tr>
                        <tr>
                            <td style="width: 50%;" class="text-14 text-black fw-bold text-end">Payment By: </td>
                            <td style="width: 50%;" class="text-14 text-black fw-normal">&nbsp; {{ data.first_name }} {{
                                data.last_name }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 50%;" class="text-14 text-black fw-bold text-end">Channel: </td>
                            <td style="width: 50%;" class="text-14 text-black fw-normal">&nbsp; {{
                                data.medium }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 50%;" class="text-14 text-black fw-bold text-end"> Date: </td>
                            <td style="width: 50%;" class="text-14 text-black fw-normal">&nbsp; {{ data.date }} </td>
                        </tr>
                        <tr>
                            <td style="width: 50%;" class="text-14 text-black fw-bold text-end"> Time: </td>
                            <td style="width: 50%;" class="text-14 text-black fw-normal">&nbsp; {{ data.time }} </td>
                        </tr>
                        <tr>
                            <td style="width: 50%;" class="text-14 text-black fw-bold text-end mr-1">Transaction ID:
                            </td>
                            <td style="width: 50%;" class="text-13 text-black fw-normal">&nbsp; {{ data.confirmation_id
                                }}</td>
                        </tr>

                        <tr>
                            <td style="width: 50%;" class="text-14 text-black fw-bold text-end"> Payment Amount: </td>
                            <td style="width: 50%;" class="text-14 text-black fw-normal">&nbsp; {{ data.amountOriginal
                                }}
                            </td>
                        </tr>

                        <tr>
                            <td style="width: 50%;" class="text-14 text-black fw-bold text-end">Processing Fee: </td>
                            <td style="width: 50%;" class="text-14 text-black fw-normal">&nbsp;
                                {{ data.processingTotalFee }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 50%;" class="text-14 text-black fw-bold text-end"> Total Amount:</td>
                            <td style="width: 50%;" class="text-14 text-black fw-normal">&nbsp;$ {{ data.total }} </td>
                        </tr>
                        <tr>
                            <td style="width: 50%;" class="text-14 text-black fw-bold text-end"> Service Fee: </td>
                            <td style="width: 50%;" class="text-14 text-black fw-normal">&nbsp; {{
                                data.serviceChargeTotal }}
                            </td>
                        </tr>

                        <br>



                    </table>
                </div>
                <div class="">
                    <div class="text-14  text-center">
                        <p>Thank you for your payment.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="data.feesSettings" class="w-4/5 mt-5 m-auto pl-5 pr-5">
        <div class="w-full">
            <div class="flex flex-row justify-between">
                <div class="col-8">
                    <p>Payor covers transaction processing charges?</p>
                </div>
                <div class="col-4">
                    <select class="form-select form-select-sm" [(ngModel)]="feesUser" (change)="updateFees($event)">
                        <option [value]="true">Yes</option>
                        <option selected [value]="false">No</option>
                    </select>
                </div>
            </div>

            <div *ngIf="feesUser == 'false'">
                <ul>
                    <li>Sub merchant assumes costs</li>
                    <li>Both assume costs</li>
                </ul>
            </div>

            <div *ngIf="feesUser == 'true'">
                <p>Payor assumes costs</p>
                <div *ngFor="let fee of fees" class="flex">
                    <input type="radio" name="fee" [(ngModel)]="selectedFee" [value]="fee.id" id="{{ fee.id }}"
                        [checked]="selectedFee === fee.id" />
                    <divider></divider>
                    <label class="ml-2" for="{{ fee.id }}">{{ fee.label }}</label>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="data.paylinkCustomer" class="w-4/5 mt-10 m-auto pl-5 pr-5">
        <div class="flex items-center">
            <input [value]="data.linkCustomer" disabled type="text" class="form-control">
            <mat-icon class="text-[#818381]" (click)="copyLinkToClipboard()">content_copy</mat-icon>
        </div>
        <div class="flex flex-col items-center justify-center">
            <qrcode #qrCode id="qrCode" name="qrCode" [qrdata]="data.linkCustomer" [width]="256"
                [errorCorrectionLevel]="'M'"></qrcode>
            <button (click)="downloadQR(qrCode)" class="btn btn-primary">Download QR</button>
        </div>

    </div>
    <div *ngIf="data.paymentsInformation" class="p-5">
        <h5>Payments</h5>
        <div class="overflow-auto hidden md:block">
            <table class="table table-striped">
                <thead class="">
                    <tr class="text-center align-middle text-[13px] font-bold">

                        <th class="sortable">
                            Amount.

                        </th>
                        <th class="sortable">
                            Customer

                        </th>
                        <th>Chanel</th>



                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data.dataPayments" class="text-center">
                        <td>{{item.amount | currency }}</td>
                        <td>{{item.first_name}} {{item.last_name}}</td>
                        <td>{{item.type_payment}}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="data.notificationGroup" class="p-5">
        
      
    </div>


    <div class="mt-3 mb-5">
        <div class="flex justify-center ">
            <div class="space-x-4">
                <button *ngIf="data.cancelText" class="h-10 text-3xl" mat-raised-button color="secondary"
                    (click)="closeModal(false)">
                    {{ data.cancelText || "Cancel" }}
                </button>
                <button *ngIf="data.onConfirm" class="ml-auto h-10 text-3xl" mat-raised-button color="primary"
                    (click)="closeModal(true)">
                    {{ data.submitText || "Confirm" }}
                </button>
            </div>
        </div>
    </div>
</div>