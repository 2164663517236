import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: 'root',
})
export class AwsService {
    baseUrl: string = environment.serverUrl;
    constructor(private http: HttpClient) {}
    saveBrowser(id, data) {
        return this.http.post<any>(
            `${this.baseUrl}/api/browser-metrics/${id}/save_browser/`,
            data,
        );
    }

    getImages(id) {
        return this.http.get<any>(
            `${this.baseUrl}/api/aws/get-images?subdomain=${id}`,
        );
    }

    getImgBanner() {
        return this.http.get<any>(`${this.baseUrl}/api/aws/get-banner`);
    }
    getImgSlider() {
        return this.http.get<any>(`${this.baseUrl}/api/aws/get-slider`);
    }
    getImgIconBanner() {
        return this.http.get<any>(`${this.baseUrl}/api/aws/get-banner`);
    }
    getImgFooter() {
        return this.http.get<any>(`${this.baseUrl}/api/aws/get-footer`);
    }
    getImgLogo(idSubmerchant) {
        return this.http.get<any>(
            `${this.baseUrl}/api/aws/get-logo?idSubmerchant=${idSubmerchant}`,
        );
    }
    documentCustomer(data, subdomain, idCustomer) {
        console.log(data);
        const formData: FormData = new FormData();
        data.forEach((fileWithIcon, index) => {
            formData.append(`file${index + 1}`, fileWithIcon.file); // Solo el archivo, sin especificar el nombre
        });
        return this.http.put<any>(
            `${this.baseUrl}/api/aws/put-document-customer/`,
            { data, subdomain, idCustomer },
        );
    }
}
