import { FuseNavigationItem } from '@fuse/components/navigation';
export const defaultNavigation2: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: '',
        type: 'group',
        icon: 'heroicons_outline:home', //TODO Remplazar Icono
        children: [],
    },
];
