import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthorizedGuard } from 'guards/auth/authorized.guard';
//TODO Muchas de estas rutas no se usan, si no se usan las quitaremos ?
// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed-in user to the '/dashboards/project'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'home',
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'paylink/:encripted',
                loadChildren: () => import('app/paylink/paylink.routes'),
            },
            {
                path: 'confirm-paylink/:encripted',
                loadChildren: () =>
                    import('app/confirm-paylink/confirm-paylink.routes'),
            },
            {
                path: 'payment-receipt/:encripted',
                loadChildren: () =>
                    import('app/payment-receipt/payment-receipt.routes'),
            },
            {
                path: 'terms',
                loadChildren: () => import('app/terms/terms.routes'),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthorizedGuard],
        canActivateChild: [AuthorizedGuard],
        component: AdminLayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            // Dashboards
            {
                path: 'dashboards',
                children: [
                    // {
                    //     path: 'form-preview',
                    //     loadChildren: () =>
                    //         import(
                    //             'app/modules/admin/dashboards/form-preview/form-preview.routes'
                    //         ),
                    // },
                ],
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            // {
            //     path: 'paylink/:encripted',
            //     loadChildren: () => import('app/paylink/paylink.routes'),
            // },
            // 404 & Catch all
            {
                path: '404-not-found',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        'app/modules/admin/pages/error/error-404/error-404.routes'
                    ),
            },
            { path: '**', redirectTo: '404-not-found' },
            // { path: 'paylink/**', redirectTo: 'paylink/:encripted' },
        ],
    },
];
