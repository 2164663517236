export const environment = {
    local: false,
    production: false,
    serverUrl: 'https://dev-secure.cityofdonna.com',
    serverUrlWater: 'https://dev-secure.cityofdonna.com',
    frontendUrl: 'http://localhost:4200',
    recaptcha: {
        siteKey: '6LeFawkqAAAAAJSGw_fietXpHyKtqWO9rhPVGFif',
    },
    FAILURE_LOCKOUT_TIME_MINUTES: '60',
    FAILURE_ATTEMPT_LIMIT: '10',
    apiKey: '2e57683905a49b034aec187969fa7b963febe7c3',
};
