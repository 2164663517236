/* eslint-disable */
export const shortcuts = [
    {
        id: 'a1ae91d3-e2cb-459b-9be9-a184694f548b',
        label: 'CheckOut',
        description: 'Configuration fees',
        icon: 'monetization_on',
        link: '/docs/changelog',
        useRouter: true,
        modal: 'fees',
    },
    {
        id: '989ce876-c177-4d71-a749-1953c477f825',
        label: 'Global Tax',
        description: 'Configuration tax',
        icon: 'attach_money',
        link: '',
        useRouter: true,
        modal: 'openModalEditTax',
    },
    // {
    //     id: '2496f42e-2f25-4e34-83d5-3ff9568fd984',
    //     label: 'Emails Reports',
    //     description: 'Reports Emails',
    //     icon: 'mail',
    //     link: '/apps/help-center',
    //     useRouter: true,
    //     modal: 'email-report',
    // },
    {
        id: '3c48e75e-2ae7-4b73-938a-12dc655be28b',
        label: 'Emails',
        description: 'Copy payment receipts, Voids-Returns ',
        icon: 'contact_mail',
        link: '/dashboards/analytics',
        useRouter: true,
        modal: 'email-admin',
    },
    {
        id: '3c48f75e-2be7-4b73-937a-12dc655be28b',
        label: 'Price Adopt Group',
        description: 'Price Adopt Group ',
        icon: 'pets',
        link: '/dashboards/analytics',
        useRouter: true,
        modal: 'price-group',
    },
    // {
    //     id: '3c50f75l-2be9-4b73-937a-12dc980be28b',
    //     label: 'Notifications',
    //     description: 'Configure Your Shelter Notifications',
    //     icon: 'notifications',
    //     link: '/dashboards/analytics',
    //     useRouter: true,
    //     modal: 'notifications',
    // },
];
