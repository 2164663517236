<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation"
    [opened]="!isScreenSmall" (valorCambiado)="manejarValorCambiado($event)">>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20">
            <img class="w-13 h-13 rounded-full" alt="." />
        </div>
        <!-- Title -->
        <div class="flex items-center justify-center" [class.hidden]="hidden_name">
            <h1 class="text-2xl font-semibold text-white truncate max-w-[160px] md:max-w-[200px] lg:max-w-[250px]">
                </h1>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <div class="flex items-center pr-2 space-x-2  ">
            <button class="md:hidden" (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>

            <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
                <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
            </button>

            <div class="flex flex-col" (click)="sendToHome()" style="cursor: pointer;">
                <span class="font-semibold leading-none"></span>
                <span class="text-sm text-secondary leading-none">
                    <p class="m-0"></p>
                </span>
            </div>


        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages class="mr-25"></languages>


            <div class="flex flex-col">
                <span class="font-semibold leading-none capitalize">{{first_name}}</span>
                <span class="text-sm text-secondary leading-none ">
                    <p class="m-0" *ngIf="role_id == 1">Admin</p>
                    <p class="m-0" *ngIf="role_id == 2">Staff</p>
                    <p class="m-0" *ngIf="role_id == 3">Customer</p>
                    <p class="m-0" *ngIf="role_id == 7">co-Admin</p>
                </span>
            </div>

            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <div>
                    <button mat-menu-item routerLink="/dashboards/users-info">
                        <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:user'"></mat-icon>
                        <span>My Account</span>
                    </button>

                    <shortcuts></shortcuts>

                    <button mat-menu-item (click)="logout()">
                        <mat-icon class="text-secondary"
                            [svgIcon]="'heroicons_outline:arrow-left-on-rectangle'"></mat-icon>
                        <span>Logout</span>
                    </button>
                </div>
            </mat-menu>


            <!-- <shortcuts></shortcuts>
            <button mat-icon-button routerLink="/dashboards/users-info" >
                <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:user'" ></mat-icon>
            </button>
            <button mat-icon-button (click)="logout()">
                <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:arrow-left-on-rectangle'"></mat-icon>

            </button> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">IPaySolutions &copy; {{ currentYear }}</span>
    </div>
</div>